import React, { useEffect, useState } from "react";
import Header from 'helpers/Header/Header'
import './examination.css';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Route } from "react-router-dom";
import {
  getStudentRegistraionResults,
  searchStudents,
} from "examination-results/services";
import RegistrationStatus from "examination-results/registration-status";
import StudentModuleResult from "examination-results/student-module-result";

const Home = ({ history }) => {


    useEffect(() => {
      viewRegistrationStatus();
    }, []);

  const viewRegistrationStatus = async (entry) => {
    const params = {
      type: "admno",
      value: localStorage.getItem("admission"),
    };

    const studentResponse = await searchStudents(params);
    const rows = await getStudentRegistraionResults(params);
    const item = studentResponse.data.data[0];
    item.fullName = `${item.firstname} ${item.surname}`;
    const data = { student: item, rows: rows.data.data };

    history.push({
      pathname: "/examination-results/registration-status",
      data,
      search: `?admno=${item.admno}&regNo=${item.regno}`,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title="Examination Results" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
            {/* <ResultList /> */}
            <Grid container>         
                <Grid item xs={10} style={{ padding: "15px" }}>
                  
                  <Route
                    path="/examination-results/registration-status"
                    component={RegistrationStatus}
                  />
                  <Route
                    path="/examination-results/student-module-results"
                    component={StudentModuleResult}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
