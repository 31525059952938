/**
  * using this component
  * import * as Yup from 'yup'
  * import BaseSelect from 'helpers/Select'
  *

  * define form attribute
  * const country = '',

  * validation
  * const FORM_VALIDATION = Yup.object().shape({
    country: Yup.number().integer().required('Country is Required'),
   })

  const countries = [
    { id: 1, title: 'Tanzania' },
    { id: 2, title: 'Kenya' },
    { id: 3, title: 'Uganda' },
    { id: 4, title: 'Rwanda' },
    { id: 5, title: 'Burundi' },
    { id: 6, title: 'Ethiopia' },
  ]

  * by default the select will pick the name attribute for display but if your
  * data  doesn't have the name attribute you can specify which field to use by
  * passing the `itemValue` prop
  * <BaseSelect name='country' displayName='title' itemValue='title' options={countries} label='Select Country' />
*/

import React from 'react';
import { TextField, MenuItem } from '@material-ui/core'
import { useField, useFormikContext } from 'formik';

const BaseSelect = ({ name, displayName, itemValue, options, ...otheProps }) => {
  const { setFieldValue } = useFormikContext()

  const handleChange = evt => {
    const { value } = evt.target
    if (evt) {
      setFieldValue(name, value)
    }
  }

  const [field, meta] = useField(name)

  const configSelect = {
    ...field,
    ...otheProps,
    select: true,
    variant: 'outlined',
    defaultValue: '',
    fullWidth: true,
    onChange: handleChange,
  }

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {options.map((item) => (
        <MenuItem key={itemValue ? item[itemValue] : item.id} value={itemValue ? item[itemValue] : item.id}>
          {displayName ? item[displayName] : item.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default BaseSelect
