import React from "react"

import InvoiceDetailTable from './invoice-detail-table'

const ViewDebitedDetails = ({ items }) => {

const DEBIT_DETAIL_HEADERS = [
  {id: 1, name: 'number', label: 'S/N'},
  {id: 2, name: 'createdDate', label: 'CREATED DATE'},
  {id: 3, name: 'payerId', label: 'REF. NO'},
  {id: 4, name: 'description', label: 'DESCRIPTION'},
  {id: 5, name: 'amount', label: 'AMOUNT'},
  {id: 6, name: 'invoiceNo', label: 'INVOICE'},
  {id: 7, name: 'cur', label: 'CURRENCY'},
]

  return (
    <>
      <InvoiceDetailTable headers={DEBIT_DETAIL_HEADERS} items={items} />
      {/* {details && <DetailTable rows={DETAIL_ROWS} />} */}
    </>
  )
}

export default ViewDebitedDetails
