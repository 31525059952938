/**
  * using this component
  * import BaseDialog from 'helpers/BaseDialog'
  * const openDialog = false;
  * <BaseDialog openDialog={openDialog} setOpenDialog={setOpenDialog} title="DIALOG TITLE">
  *     Dialog Content
  * </BaseDialog>
  *
**/

import React from 'react'
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  paper: { minWidth: "650px", width: 'auto' },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '& div': {
      color: 'white'
    }
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: '0px',
  },
  icon: {
    cursor: 'pointer'
  }
}))

const BaseDialog = (props) => {

  const { title, children, openDialog, setOpenDialog, ...otherProps } = props;
  const classes = useStyles();

  const dialogConfig = {
    ...otherProps,
  }

  return (
    <Dialog
      {...dialogConfig}
      open={openDialog}
      >
      <DialogTitle className={classes.root}>
        <Grid container>
          <Grid item>
            <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
              {title.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <CloseIcon className={classes.icon} onClick={() => { setOpenDialog(false) }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ minHeight: '220px', height: 'auto',}}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default BaseDialog;
