import React from 'react'

const InvoiceDetailTable = ({ headers, items}) =>  {

  const thStyle = {
    backgroundColor: "#f1f5f7",
    fontWeight: "bold",
    fontFamily: "Helvetica",
    fontSize: "13px",
  }

  return (
    <div style={{ overflow:'scroll' }}  >
      <table className="table table-bordered">
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx} style={thStyle}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => (
            <tr key={"tr" + idx}>
              {headers.map((header) => (
                <td key={`td-${header.name}`}> { item[header.name] } </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InvoiceDetailTable
