import React, { useEffect, useState } from "react"
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import { Link } from "react-router-dom";
import { Route } from 'react-router-dom'
import Header from 'helpers/Header/Header'
import AmountDue from './amount-due'
import AmountPaid from './amount-paid'
import FeePayment from './fee-payment'
import Discount from './discount'
import DebitedAmount from './debit-amount'


import './fee.css'

const Home = () => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title="Fee Payment" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
              <Grid container>
                <Grid item xs={2}>
                  <Paper elevation={0} style={{height: '100%', borderRight: '1px solid #ccc'}}>
                    <Tabs
                      value={selectedTab}
                      orientation="vertical"
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab component={Link} to="/fee-payment" label="Control No"   />
                      {/* Fee Payment */}
                      <Divider />
                      <Tab component={Link} to="/fee-payment/amount-due"  label="Amount Due"  />
                      <Divider />
                      <Tab component={Link} to="/fee-payment/amount-paid"  label="Amount Paid"  />
                      <Divider />
                      <Tab component={Link} to="/fee-payment/discount"  label="Discount"  />
                      <Divider />
                      <Tab component={Link} to="/fee-payment/payment"  label="Fee Payment"  />
                      {/* debited-amount */}
                      {/* Debited Amount */}
                      <Divider />
                    </Tabs>
                  </Paper>
                </Grid>
                <Grid item xs={10} style={{ padding: '15px' }}>
                  <Route exact path="/fee-payment" component={DebitedAmount} />
                  {/* FeePayment */}
                  <Route path="/fee-payment/amount-due" component={AmountDue} />
                  <Route path="/fee-payment/amount-paid" component={AmountPaid} />
                  <Route path="/fee-payment/discount" component={Discount} />
                  <Route path="/fee-payment/payment" component={FeePayment} />
                  {/* debited-amount */}
                  {/* DebitedAmount */}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
