import React, { useState,useEffect } from "react";

const INITIAL_STATE = {
   
};

const CarryRegistration = () => {
    const [state, setState] = useState(INITIAL_STATE);

    return (
    <>
       <div className="row">
           <div className="col-sm-1"></div>
           <div className="col-sm-10">
              Carry registration
           </div>
       </div> 
    </>
  );
};

export default CarryRegistration;