import React, { useCallback, useEffect, useState } from "react";

import StudentCard from "examination-results/card";
import { DataTable, DetailTable } from "helpers/Tables";

import {
  getStudentModuleResults,
  getStudentRegistraionResults,
  getStudentRemarks,
  searchStudents,
} from "examination-results/services";

import { getUrlParamValue } from "utils/get-query-params";

const HEADERS = [
  { field: "yearOfStudyName", label: "Year Of Study" },
  { field: "academicYearName", label: "Academic Year" },
];

const STATE = {
  student: null,
  detailRows: [],
  rows: [],
  admno: "",
};

const RegistrationStatus = ({ history }) => {
  const [state, setState] = useState(STATE);

  const navigateToYearlyResults = async (e, item) => {
    e.preventDefault();
    const regNo = getUrlParamValue.regNo;
    const admno = item.admissionNo;
    const acYear = item.academicYear.academicYear;
    // const semesterId = item.semester.id;
    const year = item.yearOfStudy;

    const params = {
      regNo: admno,
      academicYear: acYear,
      year,
    };

    const paramremarks = {
      admno: admno,
      academic_year: acYear,
      year,
    };

    const resultResponse = await getStudentModuleResults(params);
    const rows = resultResponse.data.data;

    const responseRemark = await getStudentRemarks(paramremarks);
    const rowsRemark = responseRemark.data.data;

    const gpa_sem_one = rowsRemark.find((obj) => {
      return obj.semester.id === 1;
    });

    const gpa_sem_two = rowsRemark.find((obj) => {
      return obj.semester.id === 2;
    });

    const remark_sem_one = rowsRemark.find((obj) => {
      return obj.semester.id === 1;
    });

    const remark_sem_two = rowsRemark.find((obj) => {
      return obj.semester.id === 2;
    });


    const search =
      // `?academicYear=${acYear}&regNo=${regNo}&year=${year}&semesterId=${semesterId}&admno=${admno}`;
      `?academicYear=${acYear}&regNo=${regNo}&year=${year}&admno=${admno}`;

    history.push({
      pathname: "/examination-results/student-module-results",
      params: { rows },
      search: search,
      gpa_sem_one: gpa_sem_one ? gpa_sem_one.gpa : "",
      gpa_sem_two: gpa_sem_two ? gpa_sem_two.gpa : "",
      remark_sem_one: remark_sem_one ? remark_sem_one.remark : "",
      remark_sem_two: remark_sem_two ? remark_sem_two.remark : "",
    });
  };

  const generateYearlyLink = useCallback((item) => {
    return (
      <>
        <a
          style={{ textDecoration: "underline !important" }}
          href="http://#"
          onClick={(event) => navigateToYearlyResults(event, item)}
        >
          {`Year ${item.yearOfStudy} Results`}
        </a>
      </>
    );
  }, []);

  const generateacYearLink = useCallback((item) => {
    return (
      <>
        <a
          style={{ textDecoration: "underline !important" }}
          href="http://#"
          onClick={(event) => navigateToYearlyResults(event, item)}
        >
          {item.academicYear.academicYear}
        </a>
      </>
    );
  }, []);

  const generateStudentDetails = (student) => {
    return [
      {
        label: "Reg/Admn #",
        value: student.admno,
      },
      {
        label: "Programme",
        value: student.program,
      },
      {
        label: "Registration Status",
        value: student.regStatus,
      },
      {
        label: "Student Name",
        value: `${student.firstname || ""} ${student.middlename || ""} ${student.surname || ""
          }`,
      },
    ];
  };

  const generateData = (results) => {
    return results.map((res) => ({
      ...res,
      academicYearName: generateacYearLink(res),
      yearOfStudyName: generateYearlyLink(res),
    }));
  };

  const getStudentInfo = useCallback(async () => {
    const params = {
      type: "admno",
      value: getUrlParamValue.admno,
    };

    const studentResponse = await searchStudents(params);
    const rowsResponse = await getStudentRegistraionResults(params);
    const entries = generateData(rowsResponse.data.data);

    const item = studentResponse.data.data[0];
    const detailRows = generateStudentDetails(item);
    item.fullName = `${item.firstname} ${item.surname}`;

    setState((prevState) => ({
      ...prevState,
      student: item,
      rows: entries,
      detailRows,
    }));
  });

  useEffect(() => {
    const data = history.location.data;
    if (data && data.student) {
      const student = data.student;
      const detailRows = generateStudentDetails(data.student);
      const entries = generateData(data.rows);
      student.fullName = `${student.firstname} ${student.surname}`;
      setState((prevState) => ({
        ...prevState,
        student,
        rows: entries,
        detailRows,
      }));
    } else {
      getStudentInfo();
    }
  }, []);

  return (
    <>
      {state.student && (
        <StudentCard name={state.student.fullName}>
          <DetailTable width={30} rows={state.detailRows} />
          {state.rows.length > 0 && (
            <DataTable
              columns={HEADERS}
              rows={state.rows}
              paging={false}
              striped={false}
              searching={false}
            />
          )}
        </StudentCard>
      )}
    </>
  );
};

export default RegistrationStatus;
