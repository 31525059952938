export default function localStorageSetup(data, status) {
  if (status === 1) {
    localStorage.setItem("authUser", JSON.stringify(data.data));
    localStorage.setItem("id", data.data.admno);
    localStorage.setItem("campus", data.data.campus);
    localStorage.setItem("citizenship", data.data.citizenship);
    localStorage.setItem("countryOfResident", data.data.countryOfResident);
    localStorage.setItem("district", data.data.district);
    localStorage.setItem("dob", data.data.dob);
    localStorage.setItem("firstname", data.data.firstname);
    localStorage.setItem("intake", data.data.intake);
    localStorage.setItem("middlename", data.data.middlename);
    localStorage.setItem("program", data.data.program);
    localStorage.setItem("region", data.data.region);
    localStorage.setItem("sex", data.data.sex);
    localStorage.setItem("surname", data.data.surname);
    localStorage.setItem("stageid", data.data.stage);
    localStorage.setItem("sideBarStatus", status);
  }
  if (status === 2) {
    localStorage.setItem("authUser", JSON.stringify(data.data));
    localStorage.setItem("id", data.data.admno);
    localStorage.setItem("campus", data.data.campus);
    localStorage.setItem("citizenship", data.data.citizenship);
    localStorage.setItem("countryOfResident", data.data.countryOfResident);
    localStorage.setItem("district", data.data.district);
    localStorage.setItem("dob", data.data.dob);
    localStorage.setItem("firstname", data.data.firstname);
    localStorage.setItem("intake", data.data.intake);
    localStorage.setItem("middlename", data.data.middlename);
    localStorage.setItem("program", data.data.program);
    localStorage.setItem("region", data.data.region);
    localStorage.setItem("sex", data.data.sex);
    localStorage.setItem("surname", data.data.surname);
    localStorage.setItem("stageid", data.data.stage);
    localStorage.setItem("sideBarStatus", status);
  }

  return true;
}
