import React, { Component } from "react";
import { Button, Container } from "reactstrap";
import { withRouter } from "react-router";
import { FaArrowAltCircleRight } from "react-icons/fa";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: "",
      address: "",
      work_station: "",
      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
      fields: [],
      errors: [],
      bankName: "",
      accountNumber: "",
      accountName: "",
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };
  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["bankName"]) {
      formIsValid = false;
      errors["bankName"] =
        "* Please fill in bank name or Nil if you don't have any.";
    }
    if (!fields["accountNumber"]) {
      formIsValid = false;
      errors["accountNumber"] =
        "* Please fill in account number or Nil if you don't have any.";
    }
    if (!fields["accountName"]) {
      formIsValid = false;
      errors["accountName"] =
        "* Please fill in account name or Nil if you don't have any.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  nextToOlevelDetails = async (e) => {
    e.preventDefault();
    // if (this.validateForm()) {
    this.setState({ loading: true, network_error: false });
    // let payLoad = {
    //   applicationProfileId: parseInt(localStorage.getItem("id")),
    // };
    // if (parseInt(localStorage.getItem("stageid")) === 12) {
    //   let result = await changeStage(payLoad);
    //   if (result === 1) {
    //     this.setState({ loading: false, network_error: false });
    this.props.history.push("/registration-education");
    //   } else {
    //   }
    // } else {
    //   this.props.history.push("/registration-education");
    // }
    // }
  };

  submitEmploymentDetails = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let payLoad = {
        admno: parseInt(localStorage.getItem("id")),
        bankName: this.state.fields.bankName,
        accountNumber: this.state.fields.accountNumber,
        accountName: this.state.fields.accountName,
      };
      try {
        await api
          .post(params.BANK_DETAILS, payLoad)
          .then((res) => {
            if (res.status === 200) {
              // if (res.data.stsCode === "0000") {
              // this.setState({
              //   employer: "",
              //   address: "",
              //   work_station: "",
              // });
              // console.log(res.data.stsCode);
              alert("successfully added");
              window.location.reload(); //to check if the data exists to redirect to EditEmployment Details
              // } else {
              //   this.setState({
              //     employerName: " ",
              //     employerAddress: " ",
              //     workStation: "",
              //   });
              //   this.setState({ credentialError: res.data.stsMessage });
              // }
            } else {
              this.setState({
                employerName: " ",
                employerAddress: " ",
                workStation: "",
              });
              this.setState({ credentialError: res.data.stsMessage });
            }
          })
          .catch((err) => {});
      } catch (error) {}
    }
    //Endpoint
    //then window reload
  };
  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="float-left card-header-title">
              Bank Details (It is Mandatory for loan beneficiaries only)
            </div>
            <div className="float-right card-header-title">
              Application for : {localStorage.getItem("id")}
            </div>
          </div>
          <div className="card-block">
            <div className="block">
              {/* {this.state.stage === 1 ? ( */}
              <div className="row">
                <div className="col-sm-4">
                  {/* <p>
                    <b>Instructions</b>
                  </p>
                  <hr /> */}
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        {/* <ReactLoading type="cylon" color="#000" /> */}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : null}

                  {this.state.error === true ? (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <div className="alert alert-warning">
                            <strong>Warning!</strong> You are facing a
                            connection problem, please contact the system
                            administrator OR try later
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* ) : ( */}
              <>
                {/* <div className="block login"> */}
                <div>
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    {/* Fill 'Nil' in all the boxes if you don't have any bank
                    detail. */}
                    Click Skip Button if you don't have any bank details
                  </span>
                </div>
                <hr />
                <form onSubmit={this.submitEmploymentDetails}>
                  {" "}
                  {/* <div className="block login"> */}
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <label> Bank Name</label>
                      {/* <font className="label-comment">*</font> */}
                      <input
                        type="text"
                        name="bankName"
                        className="form-control input-placeholder"
                        value={this.state.bankName}
                        onChange={this.handleInputState}
                        placeholder="Bank name"
                      ></input>
                      {this.state.errors.bankName ? (
                        <div className="errorMsg">
                          {this.state.errors.bankName}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label>
                        {" "}
                        Account Number
                        {/* <font className="label-comment">*</font> */}
                      </label>
                      <input
                        type="text"
                        name="accountNumber"
                        className="form-control input-placeholder"
                        value={this.state.accountNumber}
                        onChange={this.handleInputState}
                        placeholder="account number"
                      ></input>

                      {this.state.errors.accountNumber ? (
                        <div className="errorMsg">
                          {this.state.errors.accountNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label> Account Name</label>
                      {/* <font className="label-comment">*</font> */}
                      <input
                        type="text"
                        name="accountName"
                        className="form-control input-placeholder"
                        value={this.state.accountName}
                        onChange={this.handleInputState}
                        placeholder="account name"
                      ></input>
                      {this.state.errors.accountName ? (
                        <div className="errorMsg">
                          {this.state.errors.accountName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* </div> */}
                  <br />
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Save <FaArrowAltCircleRight size={20} />
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        onClick={this.nextToOlevelDetails.bind(this)}
                      >
                        Skip <FaArrowAltCircleRight size={20} />
                      </Button>
                    </div>
                  </div>
                  <div className="row"></div>
                </form>

                {/* </div> */}
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const BankDetails2 = withRouter(BankDetails);
export default BankDetails2;
