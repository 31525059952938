import React, { useState,useEffect } from "react";
import {
  getInvoiceDetails,
} from "../fee-payment/services";
import { toCurrency, toDate } from "helpers/utils";
import ViewDebitedDetails from "./view-debited-details";

 const INITIAL_STATE = {
  admno: null,
  invoideDetails: null,
  };

const DebitAmount = () => {
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
      debitDetails();
    }, []);
  
    const debitDetails = async () => {
      const res = await getInvoiceDetails({ admno:localStorage.getItem("admission") });
      if (res.data.stsCode === "0000") {
        setState((prevState) => ({
          ...prevState,
          invoideDetails: generateInvoiceDetailsData(res.data.data),
        }));
      }
    };
 
    const generateInvoiceDetailsData = (data) => {
      return data.map((item, idx) => ({
        ...item,
        number: idx + 1,
        amount: toCurrency(item.amount),
        invoiceNo: item.invoiceNo,
        createdDate: toDate(item.createdDate),
        cur: "TZS",
      }));
    };

    return (
    <>
       <div className="row">
           <div className="col-sm-12">
          { state.invoideDetails!==null ? (
             <ViewDebitedDetails
             items={state.invoideDetails}  
            />
          ):null}   
           </div>
       </div> 
    </>
  );
};

export default DebitAmount;