import React, { Component } from "react";
import { Button, Row, Container } from "reactstrap";
import { FaArrowAltCircleRight, FaSave } from "react-icons/fa";
import toTitleconverttoTitle from "../../../util/toTitleConveter";
import tzPhoneNumberValidator from "../../../util/tzPhoneNumberValidator";
import changeStage from "../../../util/changeStage";
import { withRouter } from "react-router";
import UploadImage from "./UploadImage";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import localStorageSetup2 from "../../../util/setupLocalStorage2";

class EditPersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: "",

      id: null,
      title: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      sex: "",
      dob: "",
      mstatus: "",

      region: "",
      district: "",
      cellPhone: "",
      phone: "",

      email: "",
      address: "",

      fullname: "",
      relationship: "",
      region2: "",
      district2: "",
      phone2: "",
      address2: "",
      // -----------//
      fields: [],
      errors: [],

      info_loading: null,

      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
      user_data: "",
      nin: "",
    };
    // this.handleInputState = this.handleInputState.bind(this);
    // this.SubmitPersonalDetails = this.SubmitPersonalDetails.bind(this);
  }

  componentDidMount() {
    this.fetchImage();
  }

  componentWillReceiveProps(props) {
    console.log(props.data.nationalId);
    this.setState({
      first_name: toTitleconverttoTitle(props.data.firstname),
      last_name: toTitleconverttoTitle(props.data.surname),
      middle_name: toTitleconverttoTitle(props.data.middlename),
      sex: localStorage.getItem("sex")
        ? localStorage.getItem("sex")
        : props.data.sex,
      dob: localStorage.getItem("dob")
        ? localStorage.getItem("dob")
        : props.data.dob,

      title: localStorage.getItem("title")
        ? localStorage.getItem("title")
        : props.data.title,

      region: localStorage.getItem("region")
        ? localStorage.getItem("region")
        : props.data.region,
      district: localStorage.getItem("district")
        ? localStorage.getItem("district")
        : props.data.district,

      phone: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : props.data.cellPhone,
      email: localStorage.getItem("email")
        ? localStorage.getItem("email")
        : props.data.email,
      address: localStorage.getItem("address")
        ? localStorage.getItem("address")
        : props.data.postalAddress,

      mstatus: localStorage.getItem("mstatus")
        ? localStorage.getItem("mstatus")
        : props.data.maritalStatus,
      // nin: localStorage.getItem("nin") ? localStorage.getItem("nin") : "",

      nin: localStorage.getItem("nin")
        ? localStorage.getItem("nin")
        : props.data.nationalId,

      fullname: props.kindata.fullname,
      relationship: props.kindata.relationship,
      region2: props.kindata.region,
      district2: props.kindata.district,
      phone2: props.kindata.cellPhone,
      address2: props.kindata.postalBox,
    });
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  validateForm = () => {
    // let fields = this.state.fields;
    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "* Please select title.";
    }
    // if (!fields["first_name"]) {
    //   formIsValid = false;
    //   errors["first_name"] = "* Please fill in first name.";
    // }
    // if (!fields["middle_name"]) {
    //   formIsValid = false;
    //   errors["middle_name"] = "* Please fill in middle name.";
    // }
    // if (!fields["last_name"]) {
    //   formIsValid = false;
    //   errors["last_name"] = "* Please fill in last name.";
    // }
    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "* Please fill in date of birth.";
    }
    if (!fields["sex"]) {
      formIsValid = false;
      errors["sex"] = "* Please fill in gender.";
    }
    if (!fields["mstatus"]) {
      formIsValid = false;
      errors["mstatus"] = "* Please select marital status.";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "* Please fill in address.";
    }
    if (!fields["district"]) {
      formIsValid = false;
      errors["district"] = "* Please fill in district.";
    }
    if (!fields["region"]) {
      formIsValid = false;
      errors["region"] = "* Please fill in region.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["phone2"] || typeof fields["phone2"] === undefined) {
      formIsValid = false;
      errors["phone2"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone2"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "* Please fill in email.";
    }

    // ----------//
    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "* Please fill in address.";
    }
    if (!fields["district2"]) {
      formIsValid = false;
      errors["district2"] = "* Please fill in district.";
    }
    if (!fields["region2"]) {
      formIsValid = false;
      errors["region2"] = "* Please fill in region.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "* Please fill in address.";
    }
    if (!fields["relationship"]) {
      formIsValid = false;
      errors["relationship"] = "* Please fill in relationship.";
    }
    if (!fields["fullname"]) {
      formIsValid = false;
      errors["fullname"] = "* Please fill in fullname.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  fetchImage = async () => {
    // e.preventDefault();
    // console.log(this.state.file);
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    await api
      .post(params.FETCH_PHOTO, payLoad)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              photo: res.data.data.photo,
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              network_error: false,
            });
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  nextToEmploymentDetails = async (e) => {
    e.preventDefault();
    // console.log(this.state.first_name);

    if (this.validateForm()) {
      this.setState({ loading: true, network_error: false });
      let payLoad = {
        applicationProfileId: parseInt(localStorage.getItem("id")),
        stageId: 12,
      };
      if (
        parseInt(localStorage.getItem("stageid")) === 11 &&
        this.state.photo !== undefined
      ) {
        let result = await changeStage(payLoad);
        if (result === 1) {
          this.setState({ loading: false, network_error: false });
          this.props.history.push("/registration-employment");
          console.log(this.state.photo);
        } else {
        }
      } else if (this.state.photo === undefined) {
        // this.props.history.push("/registration-employment");
        alert("Please Upload your photo before proceeding to the next step");
        console.log(this.state.photo);
      } else {
        this.props.history.push("/registration-employment");
      }
    }
  };

  SubmitPersonalDetails = async (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      let payLoad = {
        admno: parseInt(localStorage.getItem("id")),
        title: this.state.title,
        firstname: this.state.firstname,
        middlename: this.state.middlename,
        surname: this.state.surname,
        sex: this.state.sex,
        maritalStatus: this.state.mstatus,
        dob: this.state.dob,
        cellPhone: this.state.phone,
        email: this.state.email,
        postalAddress: this.state.address,
        region: this.state.region,
        district: this.state.district,
        kinFullname: this.state.fullname,
        kinRelationship: this.state.relationship,
        kinCountry: this.state.country,
        kinRegion: this.state.region2,
        kinDistrict: this.state.district2,
        kinCellPhone: this.state.phone2,
        kinPostalBox: this.state.address2,
        nationalId: this.state.nin ? this.state.nin : "nil",
      };
      await api
        .post(params.UPDATE_PERSONAL_DETAILS, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              // const data = localStorageSetup2(res.data);
              localStorage.setItem("title", res.data.data.title);
              localStorage.setItem("sex", res.data.data.sex);
              localStorage.setItem("mstatus", res.data.data.maritalStatus);
              localStorage.setItem("dob", res.data.data.dob);
              localStorage.setItem("phone", res.data.data.cellPhone);
              localStorage.setItem("email", res.data.data.email);
              localStorage.setItem("address", res.data.data.postalAddress);
              localStorage.setItem("region", res.data.data.region);
              localStorage.setItem("district", res.data.data.district);
              localStorage.setItem("fullname", res.data.data.kinFullname);
              localStorage.setItem(
                "relationship",
                res.data.data.kinRelationship
              );
              localStorage.setItem("country", res.data.data.kinCountry);
              localStorage.setItem("region2", res.data.data.kinRegion);
              localStorage.setItem("district2", res.data.data.kinDistrict);
              localStorage.setItem("phone2", res.data.data.kinCellPhone);
              localStorage.setItem("address2", res.data.data.kinPostalBox);
              // localStorage.setItem("nin", this.state.nin);
              localStorage.setItem("nin", res.data.data.nationalId);

              // if (data === true) {
              this.setState({
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                // redirect: true,
                network_error: false,

                //get from local storage
                title: localStorage.getItem("title"),
                sex: localStorage.getItem("sex"),
                mstatus: localStorage.getItem("mstatus"),
                dob: localStorage.getItem("dob"),
                phone: localStorage.getItem("phone"),
                email: localStorage.getItem("email"),
                address: localStorage.getItem("address"),
                region: localStorage.getItem("region"),
                district: localStorage.getItem("district"),
                fullname: localStorage.getItem("fullname"),
                relationship: localStorage.getItem("relationship"),
                country: localStorage.getItem("country"),
                region2: localStorage.getItem("region2"),
                district2: localStorage.getItem("district2"),
                phone2: localStorage.getItem("phone2"),
                address2: localStorage.getItem("address2"),
                nin: localStorage.getItem("nin"),
              });
              // this.props.history.push("/personal/nextofkin");
              console.log(this.state.address);
              console.log(res.data.stsCode);
              console.log(res.data.data);
              alert("successfully updated");
              // window.location.reload();
              // }
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="float-left card-header-title">
              Edit Personal Details
            </div>
            <div className="float-right card-header-title">
              Admission No : {localStorage.getItem("id")}
            </div>
          </div>
          <div className="card-block">
            <div className="block">
              {/* {this.state.stage === 1 ? ( */}
              <div className="row">
                <div className="col-sm-4">
                  {/* <p>
                    <b>Instructions</b>
                  </p>
                  <hr /> */}
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        {/* <ReactLoading type="cylon" color="#000" /> */}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : null}

                  {this.state.error === true ? (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <div className="alert alert-warning">
                            <strong>Warning!</strong> You are facing a
                            connection problem, please contact the system
                            administrator OR try later
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* ) : ( */}
              <>
                {/* <div className="block login"> */}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  Your Own Personal Details
                </span>
                <form onSubmit={this.SubmitPersonalDetails}>
                  {" "}
                  {/* <div className="block login"> */}
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="block login">
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Title</label>
                            <font className="label-comment">*</font>

                            <select
                              name="title"
                              className="selection-box  input-placeholder"
                              value={this.state.title}
                              onChange={this.handleInputState}
                            >
                              <option key={0} value="">
                                {" "}
                                -- Select the title --{" "}
                              </option>
                              <option key={1} value="Mr">
                                Mr
                              </option>
                              <option value="Ms">Miss</option>
                              <option value="Eng">Engineer</option>
                              <option value="Dr">Doctor</option>
                              <option value="Prof">Proffesor</option>
                            </select>
                            {this.state.errors.title ? (
                              <div className="errorMsg">
                                {this.state.errors.title}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label>
                              {" "}
                              First name
                              <font className="label-comment">*</font>
                            </label>
                            <input
                              type="text"
                              name="first_name"
                              className="form-control input-placeholder"
                              disabled
                              value={this.state.first_name}
                              onChange={this.handleInputState}
                              placeholder="first name"
                            ></input>
                            {/* {console.log(this.state.first_name)} */}

                            {/* {this.state.errors.first_name ? (
                              <div className="errorMsg">
                                {this.state.errors.first_name}
                              </div>
                            ) : null} */}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Middle name</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="middle_name"
                              className="form-control input-placeholder"
                              disabled
                              value={this.state.middle_name}
                              onChange={this.handleInputState}
                              placeholder="middle name"
                            ></input>
                            {/* {this.state.errors.middle_name ? (
                              <div className="errorMsg">
                                {this.state.errors.middle_name}
                              </div>
                            ) : null} */}
                          </div>
                          <div className="col-sm-3"></div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Last name</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="last_name"
                              className="form-control input-placeholder"
                              disabled
                              value={this.state.last_name}
                              onChange={this.handleInputState}
                              placeholder="last name"
                            ></input>
                            {/* {this.state.errors.last_name ? (
                              <div className="errorMsg">
                                {this.state.errors.last_name}
                              </div>
                            ) : null} */}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Date of birth</label>
                            <font className="label-comment">*</font>
                            <input
                              type="date"
                              name="dob"
                              className="form-control input-placeholder"
                              value={this.state.dob}
                              onChange={this.handleInputState}
                            ></input>
                            {this.state.errors.dob ? (
                              <div className="errorMsg">
                                {this.state.errors.dob}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Gender</label>
                            <font className="label-comment">*</font>
                            <select
                              name="sex"
                              className="selection-box  input-placeholder"
                              value={this.state.sex}
                              onChange={this.handleInputState}
                            >
                              <option key={0} value="">
                                {" "}
                                -- Select gender --{" "}
                              </option>
                              <option key={1} value="M">
                                Male
                              </option>
                              <option key={2} value="F">
                                Female
                              </option>
                            </select>
                            {this.state.errors.sex ? (
                              <div className="errorMsg">
                                {this.state.errors.sex}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <hr />
                        {/* ----phase 0----- */}
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Marital Status</label>
                            <font className="label-comment">*</font>
                            <select
                              name="mstatus"
                              className="selection-box  input-placeholder"
                              value={this.state.mstatus}
                              onChange={this.handleInputState}
                            >
                              <option key={0} value="">
                                {" "}
                                -- Marital status --{" "}
                              </option>
                              <option key={1} value="Married">
                                Single
                              </option>
                              <option key={2} value="Single">
                                Married
                              </option>
                            </select>
                            {this.state.errors.mstatus ? (
                              <div className="errorMsg">
                                {this.state.errors.mstatus}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> P.O Box</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="address"
                              className="form-control input-placeholder"
                              value={this.state.address}
                              onChange={this.handleInputState}
                              placeholder="box"
                            ></input>
                            {this.state.errors.address ? (
                              <div className="errorMsg">
                                {this.state.errors.address}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> District</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="district"
                              className="form-control input-placeholder"
                              value={this.state.district}
                              onChange={this.handleInputState}
                              placeholder="district"
                            ></input>
                            {this.state.errors.district ? (
                              <div className="errorMsg">
                                {this.state.errors.district}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                        {/* -----------phase I--------- */}
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Region</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="region"
                              className="form-control input-placeholder"
                              value={this.state.region}
                              onChange={this.handleInputState}
                              placeholder="region"
                            ></input>
                            {this.state.errors.region ? (
                              <div className="errorMsg">
                                {this.state.errors.region}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Mobile No</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="phone"
                              className="form-control input-placeholder"
                              value={this.state.phone}
                              onChange={this.handleInputState}
                              placeholder="phone"
                            ></input>
                            {this.state.errors.phone ? (
                              <div className="errorMsg">
                                {this.state.errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Email</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="email"
                              className="form-control input-placeholder"
                              disabled
                              value={this.state.email}
                              onChange={this.handleInputState}
                              placeholder="email"
                            ></input>
                            {/* {this.state.errors.email ? (
                              <div className="errorMsg">
                                {this.state.errors.email}
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> NIDA NUMBER</label>
                            <font className="label-comment"></font>
                            <input
                              type="text"
                              name="nin"
                              className="form-control input-placeholder"
                              value={this.state.nin}
                              onChange={this.handleInputState}
                              placeholder="NIDA Number"
                            ></input>
                            {/* {this.state.errors.email ? (
                              <div className="errorMsg">
                                {this.state.errors.email}
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Your Next Of Kin Personal Details
                      </span>
                      {/* ----phase II-- */}
                      <div className="block login">
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Next Of Kin Full Name</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="fullname"
                              className="form-control input-placeholder"
                              value={this.state.fullname}
                              onChange={this.handleInputState}
                              placeholder="fullname"
                            ></input>
                            {this.state.errors.fullname ? (
                              <div className="errorMsg">
                                {this.state.errors.fullname}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Relationship</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="relationship"
                              className="form-control input-placeholder"
                              value={this.state.relationship}
                              onChange={this.handleInputState}
                              placeholder="relationship"
                            ></input>
                            {this.state.errors.relationship ? (
                              <div className="errorMsg">
                                {this.state.errors.relationship}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Mobile Phone</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="phone2"
                              className="form-control input-placeholder"
                              value={this.state.phone2}
                              onChange={this.handleInputState}
                              placeholder="mobile"
                            ></input>
                            {this.state.errors.phone2 ? (
                              <div className="errorMsg">
                                {this.state.errors.phone2}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                        {/* ------phase III------ */}
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label> Address</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="address2"
                              className="form-control input-placeholder"
                              value={this.state.address2}
                              onChange={this.handleInputState}
                              placeholder="address"
                            ></input>
                            {this.state.errors.address2 ? (
                              <div className="errorMsg">
                                {this.state.errors.address2}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> District</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="district2"
                              className="form-control input-placeholder"
                              value={this.state.district2}
                              onChange={this.handleInputState}
                              placeholder="district"
                            ></input>
                            {this.state.errors.district2 ? (
                              <div className="errorMsg">
                                {this.state.errors.district2}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group col-sm-4">
                            <label> Region</label>
                            <font className="label-comment">*</font>
                            <input
                              type="text"
                              name="region2"
                              className="form-control input-placeholder"
                              value={this.state.region2}
                              onChange={this.handleInputState}
                              placeholder="district"
                            ></input>
                            {this.state.errors.region2 ? (
                              <div className="errorMsg">
                                {this.state.errors.region2}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="block login">
                        <UploadImage />{" "}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <br />
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Save <FaSave size={20} />
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        onClick={this.nextToEmploymentDetails.bind(this)}
                      >
                        Next <FaArrowAltCircleRight size={20} />
                      </Button>
                    </div>
                    <div className="form-group col-sm-3"></div>
                  </div>
                </form>
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EditPersonalDetails2 = withRouter(EditPersonalDetails);
export default EditPersonalDetails2;
