var Params = {
  RESET_PASSWORD: "/api/resouces/check-email",
  PROGRAMME_REQUIREMENT: "/api/lookup/entryQualification/all",
  CURRENT_INTAKE: "/api/lookup/config/initial_data",
  COUNTRY_LIST: "/api/lookup/country/all",
  REGION_LIST: "/api/lookup/region/all",
  DISTRICT_LIST: "/api/lookup/district",
  PRIMARY_EDUCATION: "/api/primarySchool",
  CHANGE_STAGE: "/api/applStage/next/",
  ADD_PRIMARY_EDUCATION: "/api/primarySchool/add",
  CHECKING_ACCOUNT_EXISTENCE: "/api/lookup/necta/olevel",
  OLEVEL_LIST: "/api/nectadata/olevel/",

  ADD_HIGH_SCHOOL_EDUCATION: "/api/lookup/necta/alevel",
  ALEVEL_LIST: "/api/nectadata/alevel/",
  REQUEST_NECTA_DATA: "/api/resources/necta-data",
  //College Information
  GET_COLLEGE_LIST: "/api/college/list_by_profile_id",
  SUBMIT_OUT_DATA: "/api/out/particulars",
  SUBMIT_NVA_DATA: "/api/nacte/particulars",
  SUBMIT_COLLEGE_DATA: "/api/college/save",

  //applying programs
  CAMPUS_LIST: "/api/campus/list",
  CAMPUS_PROGRAM_LIST: "/api/campus/programs",
  ADD_PROGRAM: "/api/appliedProgram/create",
  GET_APPLIED_PROGRAM: "/api/appliedProgram/query/",
  ACCOUNT_RESET_REQUEST: "/api/applicant/indexnoyear/details",
  CONTROL_NUMBER: "/api/gepg/queryControlNo",

  USER_LOGIN: "/api/authenticate",
  USER_ACCOUNT: "/api/applicant/account/",

  //next of kin
  ADD_NEXTOFKIN: "/api/nextOfKin/create",
  NEXT_OF_KIN_LIST: "/api/nextOfKin/query/",

  //submit application and preselectiom
  SUBMIT_APPLICATION: "/api/preselection/run",

  //reset programme
  CHANGE_PROGRAMME: "/api/appliedProgram/reset",

  //confirmation
  CONFIRMATION_STATUS: "",

  //Get All Programmes
  ALL_PROGRAMMES: "/api/program/all",

  //Registration APIs
  AUTHENTICATE_REGISTRATION: "/api/admission/login",

  NEXT_OF_KIN_LIST: "/api/nextOfKin/query/",

  GET_PERSONAL_DETAILS: "",
  ADD_PERSONAL_DETAILS: "",
  UPDATE_PERSONAL_DETAILS: "/api/admission/update",

  UPLOAD_PHOTO: "/api/admission/photo/save",
  FETCH_PHOTO: "/api/admission/photo/fetch",

  GET_EMPLOYMENT_DETAILS: "/api/employer/fetch",
  ADD_EMPLOYMENT_DETAILS: "/api/employer/create",
  UPDATE_EMPLOYMENT_DETAILS: "/api/employer/update",

  BANK_DETAILS: "/api/student-bank-account",

  PERFORM_DECLARATION: "/api/admission/declaration",
  RESET_PASSWORD: "",
};

export default Params;
