import React from "react"

const Header = ({title}) => {
  return (
    <div>
      <div className="float-left card-header-title">
        {title}
      </div>
      <div className="float-right card-header-title">
      </div>
    </div>
  )
}

export default Header
