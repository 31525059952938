import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "650px", width: "auto" },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& div": {
      color: "white",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  icon: {
    cursor: "pointer",
  },
}));

const ConfirmDialog = (props) => {
  const classes = useStyles();
  const { title, text, children, open, setOpen, ...otherProps } = props;

  const dialogConfig = {
    ...otherProps,
  };

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...dialogConfig}
    >
      <DialogTitle className={classes.root} id="confirmation-dialog-title">
        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {text}
      </DialogContent>
      <DialogActions>
        {children}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
