import React, { Component } from "react";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
// import Tabs from "react-bootstrap/Tabs";
// import Tab from "react-bootstrap/Tab";
// availity-reactstrap-validation
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import params from "../../util/api_routes/params";
import api from "../../util/api";
import { checkLogin, apiError } from "../../store/actions";
import localStorageSetup_Login from "../../util/setupLocalStorage_Login";
import localStorageSetup from "../../util/setupLocalStorage";
import axios from "axios";

import {
  authenticateUser,
  getUserDetails,
} from "./services/authentication-service";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      surname: "",
      admno: "",
      campusId: "",
      programId: "",
      fields: {},
      errors: {},
      redirect: false,
      requirements: [],
      isFetching: false,
      network_error: "",
      credentialError: "",
      user_data: [],
      hide: true,
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let user = null;
      const response = await authenticateUser({
        username: this.state.username,
        password: this.state.password,
      });

      if (response.status === 200) {
        user = await getUserDetails(response.data.access_token);
      }
      if (user.status === 200) {
        console.log(user);
        let status = await localStorageSetup_Login(user.data.data);
        this.getApplicantProfile();
        this.setState({ admno: this.state.user_data.admission.admno });
        this.authenticate();
      }
    } catch (error) {
      this.setState({ username: "", password: "" });
      this.setState({ credentialError: "Invalid Credentials" });
      localStorage.clear();
    }
  };

  getApplicantProfile = () => {
    this.setState({
      user_data: JSON.parse(localStorage.getItem("authenticatedUser")),
    });
  };

  authenticate = async (e) => {
    // e.preventDefault();
    //check the information if exists
    this.setState({ info_loading: true });

    if (this.validateForm()) {
      let payLoad = {
        admno: this.state.admno,
        surname: "test",
        campusId: "test",
        programId: "test",
      };
      const token = JSON.parse(localStorage.getItem("token"));
      try {
        await axios
          .post(params.AUTHENTICATE_REGISTRATION, payLoad, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                const status = localStorageSetup(res.data, 2);
                if (status === true) {
                  this.setState({
                    redirect: true,
                  });
                  window.location.reload();
                }
              } else {
                this.setState({
                  admno: " ",
                  surname: " ",
                  campusId: "",
                  programId: "",
                });
                this.setState({ credentialError: res.data.stsMessage });
                localStorage.clear();
              }
            }
          })
          .catch((err) => {});
      } catch (error) {}
    }
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"] || typeof fields["username"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    if (!fields["password"] || typeof fields["password"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  componentDidMount() {
    this.props.apiError("");
    this.getEntryQualification();
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  getEntryQualification = async () => {
    this.setState({ isFetching: true });
    await api
      .get(params.PROGRAMME_REQUIREMENT)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              network_error: false,
              isFetching: false,
              requirements: res.data.data,
            });
          } else {
          }
        } else {
          this.setState({ network_error: false, isFetching: false });
        }
      })
      .catch((error) => {});
  };

  render() {
    const list_style = {
      padding: "10px",
    };

    const { hide } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="row">
            <br />
          </div>

          <div className="col-sm-12">
            <div className="card">
              {/* <div className="card-header"></div> */}
              <div className="card-block">
                <div className="row">
                  {/** ------------  Login Form ---------- **/}
                  <div className="col-lg-12 col-xl-4">
                    <div className="block login">
                      <div className="block-title">
                        <br></br>
                        <h6>
                          <i className="ti-lock"></i> Login In
                        </h6>
                        <br />
                      </div>

                      {this.state.redirect && <Redirect to="/landing" />}

                      {this.state.errors.username ||
                      this.state.errors.password ? (
                        <div className="errorMsg">
                          {this.state.errors.username}{" "}
                          <i className="ti-hand-point-down"></i>
                        </div>
                      ) : null}

                      {this.state.credentialError ? (
                        <div className="errorMsg">
                          {this.state.credentialError}{" "}
                          <i className="ti-hand-point-down"></i>
                        </div>
                      ) : null}
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <label>Username </label>
                          <input
                            name="username"
                            className={
                              this.state.errors.username
                                ? "input-group-danger form-control"
                                : "form-control"
                            }
                            type="text"
                            value={this.state.username}
                            onChange={this.handleInputState}
                            autoComplete="off"
                            placeholder="REGISTRATION NUMBER"
                          />
                        </div>

                        <div className="form-group">
                          <label>Password </label>
                          <input
                            // type="password"
                            type={hide ? "password" : "text"}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputState}
                            className="form-control"
                            placeholder="Password"
                          />
                          <span
                            className="password__show"
                            onClick={this.hideSwitch}
                            style={{
                              position: "absolute",
                              right: 20,
                            }}
                          >
                            {hide ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>

                        <div className="form-group">
                          <div className="registerlink">
                            <button className="btn btn-info btn-sm">
                              {" "}
                              Sign In
                            </button>{" "}
                            <Link to={"/register"}> Register</Link>
                            {/* &nbsp; || */}
                            {/* <Link to={"/reset-account"}>
                              {" "}
                              Forgot Password ?
                            </Link> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  {/* End of Login Form */}

                  <div className="col-lg-12 col-xl-8">
                    <div className="block login">
                      <p className="m-0 paragaraph-justification">
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {" "}
                          Welcome to DMI Students Online Management System
                        </span>
                      </p>
                      <br></br>
                      <p className="m-0">
                        <b>
                          This is an online platform, which will be handling the
                          whole registration process for both continuing and
                          fresh students
                        </b>
                      </p>
                      <p className="m-0" style={list_style}>
                        <li>
                          Click Register then enter Username&Password used
                          during application .Here all your details will be
                          displayed and you will receive control number for
                          payment of required tuition and administrative fees
                        </li>
                        <li>
                          Make payment of the required fees through either
                          Banks, Tigopesa, Mpesa, Halopesa, T-pesa,or Airtel
                          Money then go personal details
                        </li>
                        <li>
                          Upload recent passport size and Fill all the required
                          information missing, then save and click next;
                        </li>
                        <li>Confirm your NHIF status</li>
                        <li>
                          Bring your admission number appear at your right side
                          to the admission office for verification
                        </li>
                        <li>
                          After verification, the designated officer will give
                          you registration number
                        </li>
                        <li>
                          Use the registration number given to login the online
                          registration platform as Username&Password{" "}
                        </li>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
