import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

class GuestHeader extends Component {
  render() {
    return (
      <div>
        <div>
          <header className="col-12 px-0 bottom">
            <div className="col-12 px-0  gradient-primary">
              <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12 px-0">
                <div className="row top_nav">
                  <div className="col-6 sm-hide md-show pl-0">
                    <ul className="list-inline mx-auto justify-content-left"></ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="col-md-10 offset-md-1 col-xs-12 col-sm-12 mb-2 mt-0 top-middle">
                <div className="row px-xs-2">
                  <div className="col-md-2 col-sm-2 col-xs-2 float-left text-left pt-1">
                    <img
                      src="/img/arm.png"
                      alt="emblem"
                      className="mx-auto img-fluid emblem"
                    />
                  </div>
                  <div className="col-md-8 col-sm-8 col-xs-8 text-center pt-3 pt-xs-0">
                    <h2 className="mb-0 title title-main">
                      Dar es Salaam Maritime Institute
                    </h2>
                    <br></br>
                    <h2 className="mb-0 title title-main">
                      STUDENT ONLINE MANAGEMENT SYSTEM (SOMAS)
                    </h2>
                  </div>
                  <div className="col-md-2 col-sm-2 col-xs-2 text-right client-logo pt-1 pr-0">
                    <img
                      src="/img/dmi_logo.png"
                      alt="TIA-logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}

export default GuestHeader;
