import React, { useState,useEffect } from "react";
import ViewDuePaymentDetails from "./view-due-payment.details";
import {
  getAmountDue,
  getDueDetails,
  getHelsbDueAmount,
} from "../fee-payment/services";
import { toCurrency, toDate } from "helpers/utils";

 const INITIAL_STATE = {
  admno: null,
  dueDetails: null,
  };

const AmountDue = () => {
    const [state, setState] = useState(INITIAL_STATE);

    
    useEffect(() => {
      dueDetails();
    }, []);
   
    const dueDetails = async () => {
      const dueDetails = await getDueDetails({ admno: localStorage.getItem("admission") });
      const helsbDue = await getHelsbDueAmount({ admno: localStorage.getItem("admission") });
      const studentDue = await getAmountDue({ admno: localStorage.getItem("admission") });
  
      setState((prevState) => ({
        ...prevState,
        dueDetails: {
          helsbDue: helsbDue.data.data,
          amtDue: studentDue.data.data,
          dueDetail: dueDetails.data.data,
        },
      }));
    };

    return (
    <>
       <div className="row">
           <div className="col-sm-12">
           { state.dueDetails!==null ? (
           <ViewDuePaymentDetails
            dueDetails={state.dueDetails}
           />
           ):null}  
           </div>
       </div> 
    </>
  );
};

export default AmountDue;