import axios from "api/axios";

const REGISTRATION_STATUS = "/api/student_registration/is-registered";
const REGISTER = "/api/student_registration/create";


//SEARCH BY CONTROL NUMBER
const checkRegistrationStatus = async (params) => {
    return await axios.get(`${REGISTRATION_STATUS}`, {params});
};

const studentRegistration = async (payload) => {
    return await axios.post(`${REGISTER}`, payload);
}; 


export {
  checkRegistrationStatus,
  studentRegistration,
};
