import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import BaseTextField from "helpers/TextField";
import Grid from "@material-ui/core/Grid";
import { API } from "./services";
import changeStage from "../../../util/changeStage";
import {
  viewControlNumber,
  viewPaymentStatus,
  nhifGeneralStatus,
} from "./services";

const FORM_INITIAL_STATE = {
  nhif_no: "",
  apply_card: false,
  controlNo: null,
  payment_status: null,
  isVerified: "",
  isWaived: "",
  isRegistered: "",
  isCardApplied: "",
  controlNo: "",
  isPaid: "",
};

const DisplayNhifControlno = (props) => {
  const [state, setState] = useState(FORM_INITIAL_STATE);
  let history = useHistory();

  const handleSearch = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("admission"),
    };

    // return
    // setState((prevState) => ({
    //   ...prevState,
    //   control_no: 990333002243,
    // }));

    viewControlNumber(payLoad).then((res) => {
      console.log(res);
      if (res.status === 200) {
        // if (res.data.stsCode === "0000") {
        setState((prevState) => ({
          ...prevState,
          isVerified: res.data.isVerified,
          isWaived: res.data.isWaived,
          isRegistered: res.data.isRegistered,
          isCardApplied: res.data.isCardApplied,
          controlNo: res.data.controlNo,
          isPaid: res.data.isPaid,
        }));
        helpers.resetForm();
        // }
      } else {
        return;
      }
    });
  };

  const handleSearchPayment = async (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
    };

    // setState((prevState) => ({
    //   ...prevState,
    //   payment_status: "Paid",
    // }));
    console.log(parseInt(localStorage.getItem("stageid")));

    viewPaymentStatus(payLoad).then((res) => {
      console.log(res);
      if (res.status === 200) {
        // if (res.data.stsCode === "0000") {
        setState((prevState) => ({
          ...prevState,
          isVerified: res.data.isVerified,
          isWaived: res.data.isWaived,
          isRegistered: res.data.isRegistered,
          isCardApplied: res.data.isCardApplied,
          controlNo: res.data.controlNo,
          isPaid: res.data.isPaid,
        }));
        helpers.resetForm();
        // }
      } else {
        return;
      }
    });
  };

  const checkNhifGeneralStatusControlNumber = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
    };

    // setState((prevState) => ({
    //   ...prevState,
    //   isVerified: 0,
    //   isWaived: 0,
    //   isRegistered: 0,
    //   isCardApplied: 1,
    //   controlNo: 0,
    //   isPaid: 0,
    // }));

    nhifGeneralStatus(payLoad).then((res) => {
      console.log(res.status);
      if (res.status === 200) {
        setState((prevState) => ({
          ...prevState,
          isVerified: res.data.isVerified,
          isWaived: res.data.isWaived,
          isRegistered: res.data.isRegistered,
          isCardApplied: res.data.isCardApplied,
          controlNo: res.data.controlNo,
          isPaid: res.data.isPaid,
        }));
      } else {
        return;
      }
    });
  };

  const checkNhifGeneralStatusPayment = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
    };

    // setState((prevState) => ({
    //   ...prevState,
    //   isVerified: 0,
    //   isWaived: 0,
    //   isRegistered: 0,
    //   isCardApplied: 1,
    //   controlNo: 0,
    //   isPaid: 0,
    // }));

    nhifGeneralStatus(payLoad).then((res) => {
      console.log(res.status);
      if (res.status === 200) {
        setState((prevState) => ({
          ...prevState,
          isVerified: res.data.isVerified,
          isWaived: res.data.isWaived,
          isRegistered: res.data.isRegistered,
          isCardApplied: res.data.isCardApplied,
          // controlNo: res.data.controlNo,
          isPaid: res.data.isPaid,
        }));
      } else {
        return;
      }
    });
  };
  const moveNextStageCheck = async (helpers, values) => {
    let payLoadStage = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 15,
    };

    if (
      parseInt(localStorage.getItem("stageid")) === 14 &&
      state.payment_status === "Paid"
    ) {
      let result = await changeStage(payLoadStage);
      if (result === 1) {
        console.log(parseInt(localStorage.getItem("stageid")));
        console.log(state.payment_status);
        setState({ loading: false, network_error: false });
        history.push("/registration-declaration");
      } else {
      }
    } else {
      history.push("/registration-declaration");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h6>View Control Number and Payment status</h6>

          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) =>
              checkNhifGeneralStatusControlNumber(values, helpers)
            }
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <button
                  style={{ marginTop: "20px" }}
                  className="btn btn-round  btn-md btn-primary form-inline-button"
                  type="submit"
                  name="submit"
                >
                  View Control Number{" "}
                </button>
                <br />
                {/* {state.control_no ? state.control_no : <></>} */}
                {state.controlNo ? state.controlNo : <></>}
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) =>
              checkNhifGeneralStatusPayment(values, helpers)
            }
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <button
                  style={{ marginTop: "20px" }}
                  className="btn btn-round  btn-md btn-primary form-inline-button"
                  type="submit"
                  name="submit"
                >
                  View Payment{" "}
                </button>
                <br />
                {/* {state.payment_status ? state.payment_status : <></>} */}
                {state.isPaid == 1 ? "Paid" : <>Not Paid</>}
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) => moveNextStageCheck(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                {state.isPaid == 1 ? (
                  <>
                    <button
                      style={{ marginTop: "20px" }}
                      className="btn btn-round  btn-md btn-primary form-inline-button"
                      type="submit"
                      name="submit"
                    >
                      Continue to next stage
                    </button>
                  </>
                ) : null}
                <br />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default DisplayNhifControlno;
