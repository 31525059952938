import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GuestFooter from "./HorizontalLayoutGuest/GuestFooter";
import GuestHeader from "./HorizontalLayoutGuest/GuestHeader";
import "../assets/css/master.css";
import "../assets/css/style.css";

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentage + " | SOMAS STUDENTS PLATFORM";
  }
  render() {
    return (
      <React.Fragment>
        <GuestHeader />
        {this.props.children}
        <GuestFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(NonAuthLayout);
