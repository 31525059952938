/**
  * using this component
  * import * as Yup from 'yup'
  * import BaseTextField from 'helpers/TextField'
  *

  * define form attribute
  * const fullName = '',
  * const message = '',

  * validation
  * const FORM_VALIDATION = Yup.object().shape({
     fullName: Yup.string().required('Full Name is Required'),
     message: Yup.string().required('Message is Required'),
   })

  <BaseTextField name='firstName' label='First Name' />

  * if you want a bigger text field just pass the options like this
  * <TextField name='message' label='Message ' multiline={true} rows={4} />
*/

import React from 'react'
import { TextField } from '@material-ui/core'
import { useField } from 'formik'

const BaseTextField = ({name, ...otherProps}) => {
  const [field, meta] = useField(name)
  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined',
  }
  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }
  return(
    <TextField  {...configTextField }/>
  )
}

export default BaseTextField;
