import React, { useState,useEffect } from "react";
import ViewPaidDetails from "./view-paid-amt-details";
import { toCurrency, toDate } from "helpers/utils";
import {
  getPaymentDetails,
} from "../fee-payment/services";

  const INITIAL_STATE = {
  admno: null,
  paidDetails: null,
  };

const AmountPaid = () => {
    const [state, setState] = useState(INITIAL_STATE);
    
    useEffect(() => {
      paidDetails();
    }, []);
  
    const paidDetails = async () => {
      const res = await getPaymentDetails({ admno: localStorage.getItem("admission") });
      if (res.data.stsCode === "0000") {
        setState((prevState) => ({
          ...prevState,
          isPaidDialogOpen: true,
          paidDetails: generatePaidDetailsData(res.data.data),
        }));
      }
    };

    const generatePaidDetailsData = (data) => {
      return data.map((item, idx) => ({
        ...item,
        number: idx + 1,
        amountPaid: toCurrency(item.amountPaid),
        gepgReceiptNo: item.gepgReceiptNo,
        datePaid: toDate(item.datePaid),
      }));
    };
    
    return (
    <>
       <div className="row">
           <div className="col-sm-12">
           { state.paidDetails !== null ? (
           <ViewPaidDetails
           items={state.paidDetails}
          />
          ):null}   
           </div>
       </div> 
    </>
  );
};

export default AmountPaid;